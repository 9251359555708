export type InstagramModelJSON = {
  url: string,
}

export type InstagramModelArgs = {
  url: string,
}

export class InstagramModel {
  public readonly url: string

  constructor(args: InstagramModelArgs) {
    this.url = args.url
      .trim()
      .replace(/(\s)+/g, ' ')
      .replace(/[Ａ-Ｚａ-ｚ０-９]/g,
        (s) => String.fromCharCode(s.charCodeAt(0) - 0xFEE0))
  }

  public toJSON(): InstagramModelJSON {
    return {
      url: this.url,
    }
  }

  static fromJSON(json: InstagramModelJSON): InstagramModel {
    return new InstagramModel({
      url: json.url,
    })
  }
}
