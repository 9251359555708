export type TwitterModelJSON = {
  url: string,
}

export type TwitterModelArgs = {
  url: string,
}

export class TwitterModel {
  public readonly url: string

  constructor(args: TwitterModelArgs) {
    this.url = args.url
      .trim()
      .replace(/(\s)+/g, ' ')
      .replace(/[Ａ-Ｚａ-ｚ０-９]/g,
        (s) => String.fromCharCode(s.charCodeAt(0) - 0xFEE0))
  }

  public toJSON(): TwitterModelJSON {
    return {
      url: this.url,
    }
  }

  static fromJSON(json: TwitterModelJSON): TwitterModel {
    return new TwitterModel({
      url: json.url,
    })
  }
}
