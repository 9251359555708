export type FacebookModelJSON = {
  url: string,
}

export type FacebookModelArgs = {
  url: string,
}

export class FacebookModel {
  public readonly url: string

  constructor(args: FacebookModelArgs) {
    this.url = args.url
      .trim()
      .replace(/(\s)+/g, ' ')
      .replace(/[Ａ-Ｚａ-ｚ０-９]/g,
        (s) => String.fromCharCode(s.charCodeAt(0) - 0xFEE0))
  }

  public toJSON(): FacebookModelJSON {
    return {
      url: this.url,
    }
  }

  static fromJSON(json: FacebookModelJSON): FacebookModel {
    return new FacebookModel({
      url: json.url,
    })
  }
}
