import {
  FileModel,
  FileModelJSON,
  ShopModelJSON,
} from '~/models'
import type { SerializeFrom } from '@remix-run/node'

export type LineModelJSON = {
  qrCodeFile: FileModelJSON
  shop?: ShopModelJSON
}

export type LineModelArgs = {
  qrCodeFile: FileModel
}

export class LineModel {
  public readonly qrCodeFile: FileModel

  constructor(args: LineModelArgs) {
    if (!args.qrCodeFile.belongsToShop)
      throw new Error(`line qr code needs to belong to shop: ${JSON.stringify(args)}`)
    this.qrCodeFile = args.qrCodeFile
  }

  public toJSON(): LineModelJSON {
    return {
      qrCodeFile: this.qrCodeFile.toJSON()
    }
  }

  public static fromJSON(json: LineModelJSON | SerializeFrom<LineModelJSON>) {
    return new LineModel({
      qrCodeFile: FileModel.fromJSON(json.qrCodeFile)
    })
  }
}
