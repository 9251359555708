import {
  FacebookModel, FacebookModelJSON,
  WebsiteModel, WebsiteModelJSON,
  InstagramModel, InstagramModelJSON,
  LineModel, LineModelJSON,
  PhoneNumberModel,
  TwitterModel, TwitterModelJSON,
} from '~/models'
import type { SerializeFrom } from '@remix-run/node'

export type ContactModelJSON = {
  phoneNumber: string
  email: string
  line?: LineModelJSON
  facebook?: FacebookModelJSON,
  website?: WebsiteModelJSON,
  instagram?: InstagramModelJSON,
  twitter?: TwitterModelJSON,
}

export type ContactModelArgs = {
  phoneNumber: PhoneNumberModel
  email: string
  line?: LineModel
  facebook?: FacebookModel
  website?: WebsiteModel
  instagram?: InstagramModel
  twitter?: TwitterModel
}

export class ContactModel {
  public readonly phoneNumber: PhoneNumberModel
  public readonly email: string
  public readonly line?: LineModel
  public readonly facebook?: FacebookModel
  public readonly website?: WebsiteModel
  public readonly instagram?: InstagramModel
  public readonly twitter?: TwitterModel

  constructor(args: ContactModelArgs) {
    this.phoneNumber = args.phoneNumber
    this.email = args.email
    this.line = args.line
    this.facebook = args.facebook
    this.website = args.website
    this.instagram = args.instagram
    this.twitter = args.twitter
  }

  public toJSON(): ContactModelJSON {
    return {
      phoneNumber: this.phoneNumber.toJSON(),
      email: this.email,
      line: this.line?.toJSON(),
      facebook: this.facebook?.toJSON(),
      website: this.website?.toJSON(),
      instagram: this.instagram?.toJSON(),
      twitter: this.twitter?.toJSON(),
    }
  }

  public static fromJSON(json: ContactModelJSON | SerializeFrom<ContactModelJSON>) {
    return new ContactModel({
      phoneNumber: new PhoneNumberModel(json.phoneNumber),
      email: json.email,
      line: json.line ? LineModel.fromJSON(json.line) : undefined,
      facebook: json.facebook ? FacebookModel.fromJSON(json.facebook) : undefined,
      website: json.website ? WebsiteModel.fromJSON(json.website) : undefined,
      instagram: json.instagram ? InstagramModel.fromJSON(json.instagram) : undefined,
      twitter: json.twitter ? TwitterModel.fromJSON(json.twitter) : undefined,
    })
  }
}
